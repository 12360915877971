<template>
    <v-snackbar v-model="show" :bottom="model.y === 'bottom'" :left="model.x === 'left'" :multi-line="model.mode === 'multi-line'" :right="model.x === 'right'" :timeout="model.timeout" :top="model.y === 'top'" :vertical="model.mode === 'vertical'" color="primary" class="text-md-center">
        {{ model.msg }}
    </v-snackbar>
</template>
<script>
export default {
    data: () => ({
        show: false,
        model: { msg: '', y: 'bottom', x: null, mode: '', timeout: 1600, color: '' }
    }),
    mounted: function() {
        this.$Event.on('alert', data => {
            for (var key in data) {
                this.model[key] = data[key];
            }
            this.show = true
        })
    },
    beforeDestroy() {
        this.model = null;
    },
    methods: {},
}
</script>
<style>
</style>