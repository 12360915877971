<template>
    <div>
        <mavon-editor id="editor" class="notemarkdown" v-model="content" :subfield='false' :defaultOpen="preview" @save="save" :toolbarsFlag='false' :editable="isedit" :boxShadow="false" :ishljs='true' :codeStyle='codeStyle' :scrollStyle='false' style="z-index:0">
        </mavon-editor>
        <div class="notefoot">
            <!-- <v-chip
       disabled
        v-for = "tag in model_note.tags" :key="tag.id"
         small
          text-color="white"
          @click=""
        >
        <a href="/tag/tag.id">{{tag.title }}</a>
      </v-chip> -->
            <!-- <a href='' v-for="tag in model_note.tags">#{{tag.title}}#</a> -->
            <v-tooltip left>
                <span>{{model_note}}</span>
            </v-tooltip>
            <v-btn type="text" @click="cancel()" text fab small v-show="isedit">
                <v-icon>cancel</v-icon>
            </v-btn>
            <v-btn type="text" @click="edit(model_note)" text fab small v-show="!isedit">
                <v-icon>edit</v-icon>
            </v-btn>
            <v-btn type="text" @click="save(model_note)" text fab small v-show="isedit">
                <v-icon>save</v-icon>
            </v-btn>
            <v-btn type="text" @click="del(model_note)" text fab small>
                <v-icon>delete_outline</v-icon>
            </v-btn>
            <v-btn type="text" small text disabled>{{model_note.created_at|formatDate}}</v-btn>
        </div>
    </div>
</template>
<script>
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// import { formatDate } from '@/utils/date'
import api from '@/api'
import time from '@/time'


export default {
    name: 'Note',
    props: ['model'],
    components: {
        'mavon-editor': mavonEditor,
    },
    filters: {
        formatDate(dtstr) {
            return time.tz_to_shanghai(dtstr)
        }
    },
    data() {
        return {
            isedit: false,
            preview: 'preview',
            codeStyle: 'androidstudio',
            model_note: this.model,
        }
    },
    computed: {
        content: {
            get() {
                let lines = this.model.content.split('\n')
                let tagline = lines[0]

                const re_tag = /#([^#\s]+)#/g;
                const arr = tagline.match(re_tag)
                if (!arr) {
                    return this.model.content
                } else {
                    let tagline_render = arr.map(tag => {
                        // return '[' + tag + '](' + 'https://)'
                        return '**'+tag+'**'
                    }).join(' ')
                    return tagline_render +'\n' + lines.slice(1).join('\n')
                }
            },
            set(val) {
                // console.log(val)
                this.model_note.content = val
            }
        }
    },
    methods: {
        edit() {
            this.isedit = !this.isedit;
            this.preview = 'edit';
            // this.content = this.model_note.content
        },
        cancel() {
            this.isedit = !this.isedit;
            this.preview = 'preview';
            // this.content = this.model_note.content;
        },
        del() {
            var truthBeTold = window.confirm("是否要删除?");
            if (truthBeTold) {
                api.note_delete(this.model_note.id).then(res => {
                    this.$Event.emit('notes_update', { event: 'd', data: res })
                })
            }
        },
        save() {
            this.isedit = !this.isedit;
            this.preview = 'preview';
            // this.model_note.content = this.content;
            api.note_update({ id: this.model_note.id, content: this.model_note.content }).then(res => {
                this.$Event.emit('notes_update', { event: 'u', data: res })
            })
        },
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.note {
    /* background-color: #FFFFFF; */
    /* text-align: left; */
    /* margin-top: 0px;
  margin-bottom: 0px; */
}

.notemarkdown {
    min-height: 20px;
    min-width: 100px;
    font-size: 15px;
    box-shadow: 0px 0px 0px;
    border: 0px solid red;
    color: grey;
    /* background: #FFF; */
}

.notefoot {
    /* background-color: #FFF; */
    /* padding-top: 0px; */
    /* padding-bottom: 10px; */
    top: -46px;
    height: 30px;
    position: relative;
    /* margin-bottom:4px; */
    text-align: right;
}

a:link {
    color: grey
}

a:visited {
    color: white
}

a:hover {
    color: white
}

a:active {
    color: white
}
</style>