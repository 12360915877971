import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import TagNote from '../views/TagNote.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/tagnote',
        name: 'tagnote',
        components: {
            default: TagNote
        },
    },
    {
        path: '/auth/local',
        name: 'auth-local',
        component: () => import('../views/AuthLocal.vue')
    },
    {
        path: '/auth/local/register',
        name: 'auth-local-register',
        component: () => import('../views/AuthLocalRegister.vue')
    },
    {
        path: '/auth/remove',
        name: 'auth-remove',
        component: () => import('../views/AuthRemove.vue')
    },
    {
        path: '/auth/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('../views/AuthForgotPassword.vue')
    },
    {
        path: '/auth/reset-password',
        name: 'auth-reset-password',
        component: () => import('../views/AuthResetPassword.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router