<template>
    <v-container style="max-width: 1000px;">
        <v-row>
            <v-col>
                <mavon-editor style="height: 200px;min-height:200px;z-index:1;font-size:16px;" :subfield="false" defaultOpen="edit" placeholder="第一行输入#Tag#" :ishljs="true" fontSize="16px" :boxShadow='true' :toolbars='toolbars' v-model="content" @save="save">
                </mavon-editor>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-item-group multiple>
                    <v-item v-for="ut in model_datetimes" :key="ut.id">
                        <v-chip label small text-color="white" @click="tagdtSelect(ut)" :color="ut.selected ? 'primary' : ''">
                            {{ ut.tag.title }}
                        </v-chip>
                    </v-item>
                </v-item-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-item-group multiple>
                    <v-item v-for="ut in model_tags" :key="ut.id">
                        <v-chip label small text-color="white" @click="tagSelect(ut)" :color="ut.selected ? 'primary' : ''">
                            {{ ut.tag.name }}
                        </v-chip>
                    </v-item>
                </v-item-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <note v-for='note in model_notes' :key='note.id' :model='note'></note>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="text-xs-center">
                    <v-pagination v-model="page" :length="countpage" :total-visible="5"></v-pagination>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import api from '@/api'
import Note from '@/components/Note'
import time from '@/time'

export default {
    components: {
        'mavon-editor': mavonEditor,
        'note': Note,
    },
    data() {
        return {
            // me_id:null,
            content: '',
            toolbars: {
                bold: true, // 粗体
                italic: true, // 斜体
                header: false, // 标题
                underline: true, // 下划线
                strikethrough: true, // 中划线
                mark: true, // 标记
                superscript: true, // 上角标
                subscript: true, // 下角标
                quote: true, // 引用
                ol: true, // 有序列表
                ul: true, // 无序列表
                link: true, // 链接
                // imagelink: true, // 图片链接
                code: true, // code
                table: false, // 表格
                fullscreen: false, // 全屏编辑
                readmodel: false, // 沉浸式阅读
                htmlcode: false, // 展示html源码
                help: false, // 帮助
                /* 1.3.5 */
                undo: true, // 上一步
                redo: true, // 下一步
                trash: false, // 清空
                save: true, // 保存（触发events中的save事件）
                /* 1.4.2 */
                // navigation: true, // 导航目录
                /* 2.1.8 */
                alignleft: false, // 左对齐
                // aligncenter: true, // 居中
                // alignright: true, // 右对齐
                /* 2.2.1 */
                subfield: false, // 单双栏模式
                preview: true, // 预览
            },
            model_tags: [{ 'id': 0, 'selected': true, 'level': 0, 'tag': { 'name': '全部标签' } }],
            model_datetimes: [{ 'selected': true, 'level': 0, 'tag': { 'title': '全部时间', 'id': 'all' } },
                { 'selected': false, 'level': 1, 'tag': { 'title': '今天', 'id': 'today' } },
                { 'selected': false, 'level': 1, 'tag': { 'title': '昨天', 'id': 'yesterday' } },
                { 'selected': false, 'level': 1, 'tag': { 'title': '本周', 'id': 'thisweek' } },
                { 'selected': false, 'level': 1, 'tag': { 'title': '上周', 'id': 'lastweek' } },
            ],
            model_notes: [],
            selecttags: [],
            selecttags_dt: [],
            page: 1,
            start: 0,
            limit: 10,
            count: 0,
            countpage: 1
        }
    },
    watch: {
        page(val) {
            this.start = (val - 1) * this.limit
            this.fetch();
        },
    },
    methods: {
        save() {
            api.note_create({ content: this.content }).then(res => {
                this.notes_update({event:'c', data:res});
            })
        },
        fetch() {
            let query = {
                _start: this.start,
                _limit: this.limit,
                _sort: 'id:DESC',
                created_at_gte: this.selecttags_dt[0],
                created_at_lte: this.selecttags_dt[1],
            }
            if (this.selecttags.length>0) {
                query['tags_count_gte'] = this.selecttags.length
                query['tags_in'] = this.selecttags
            }

            api.note_find(query).then(res => {
                // res.map(item=>{
                //     let tagline = item.tags.map(tag=>{
                //         return '[#' + tag.name +'](http://example.net/' + tag.id+ ')'
                //     }).join(' ')
                //     item.content = tagline + '\n' + item.content
                //     return item
                // })
                this.model_notes = res
            })
        },
        notes_update(e) {
            api.note_count().then(res => {
                this.count = res
                this.countpage = Math.trunc((this.count - 1) / this.limit) + 1
            })
            this.page = 1
            this.fetch()
            let needrefresh = true
            if (e && (e.event==='u'|e.event==='c'|e.event==='d')) {
                needrefresh = false
            }
            this.tags_update(needrefresh)
        },
        tags_update(needrefresh) {
            api.tag_find().then(res => {
                this.model_tags = res.map(item => {
                    let selected = false
                    if (!needrefresh) {
                        let tag = this.model_tags.find(tag=>tag.id==item.id)
                        if (tag) {
                            selected = tag.selected
                        }
                    }
                    return {selected:selected, level:1,id:item.id,tag:item}
                })
                this.model_tags.splice(0, 0, { id: 0, selected: true, level: 0, tag:{'name': '全部标签'} })
            })
        },
        tagdtSelect(dt) {
            this.model_datetimes.forEach(item => {
                if (dt === item) {
                    if (!item.selected) {
                        item.selected = true
                    }
                } else {
                    item.selected = false
                }
            })

            if (dt.tag.id === 'all') {
                this.selecttags_dt = time.range_all().map(item => item.toISOString())
            }

            if (dt.tag.id === 'today') {
                this.selecttags_dt = time.range_today().map(item => item.toISOString())
            }

            if (dt.tag.id === 'yesterday') {
                this.selecttags_dt = time.range_yesterday().map(item => item.toISOString())
            }

            if (dt.tag.id === 'thisweek') {
                this.selecttags_dt = time.range_thisweek().map(item => item.toISOString())
            }

            if (dt.tag.id === 'lastweek') {
                this.selecttags_dt = time.range_lastweek().map(item => item.toISOString())
            }

            this.page = 1
            this.fetch()
        },
        tagSelect(tag) {
            this.model_tags.map(item=>{
                if (item.level!==tag.level) {
                    item.selected = false
                }
            })
            tag.selected = !tag.selected
            let arr_tags = []
            this.model_tags.map(item=>{
                if (item.selected&&item.level!==0) {
                    arr_tags.push(item.id)
                }
            })

            if (arr_tags.length === 0) {
                this.model_tags[0].selected = true
            }
            this.selecttags = arr_tags

            this.page = 1
            this.fetch()
        }
    },
    mounted: function() {
        this.$Event.on('notes_update', e=>this.notes_update(e))
        this.tags_update(true);
        this.notes_update();
    },
    beforeDestroy() {},
}
</script>