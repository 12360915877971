import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
    tz_to_shanghai(dtstr) {
        return dayjs(dtstr).tz("Asia/Shanghai").format('YYYY-MM-DD HH:mm')
    },

    range_all() {
        return [dayjs('1970-00-00', 'YYYY-MM-DD'), dayjs('2100-00-00', 'YYYY-MM-DD')].map(item => item.utc())
    },

    range_today() {
        let now = dayjs().tz("Asia/Shanghai")
        return [now.startOf('day'), now.endOf('day')].map(item => item.utc())
    },

    range_yesterday() {
        let now = dayjs().tz("Asia/Shanghai").subtract(1, 'day')
        console.log(now.format())
        return [now.startOf('day'), now.endOf('day')].map(item => item.utc())
    },

    range_thisweek() {
        let now = dayjs().tz("Asia/Shanghai")
        return [now.startOf('week'), now.endOf('week')].map(item => item.utc())
    },
    range_lastweek() {
        let now = dayjs().tz("Asia/Shanghai").subtract(1, 'week')
        return [now.startOf('week'), now.endOf('week')].map(item => item.utc())
    }
}