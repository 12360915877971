<template>
    <v-app-bar app color="primary" dark>
        <div class="d-flex align-center">
            <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition" width="40" />
            <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100" src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100" />
        </div>
        <v-spacer></v-spacer>
        <v-menu open-on-hover offset-y :nudge-width="100">
            <template v-slot:activator="{ on }">
                <v-toolbar-items>
                    <v-btn class="primary" :ripple="false" text v-on="on">
                        <v-icon>account_box</v-icon>
                        <u>{{medata.username}}</u>
                    </v-btn>
                </v-toolbar-items>
            </template>
            <v-list class="primary" dark dense>
                <v-list-item v-for="(item, index) in items" :key="index" :to="item.path">
                    <v-list-item-title class="caption">{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            routes: this.$router.options.routes,
            medata: { name: '游客' },
            items: [
                { title: '退出', path: '/auth/remove' },
                { title: '修改密码', path: '/auth/reset-password' },
            ],
        };
    },
    watch: {
        '$route'(to, from) {
            console.log(from)
        }
    },
    props: [],
    mounted: function() {
        this.$Event.on('auth_done', e => this.auth_done(e))
        let medata = window.localStorage.getItem('me');
        this.auth_done(JSON.parse(medata))
    },
    beforeDestroy() {

    },
    methods: {
        auth_done(res) {
            console.log(res)
            this.medata = res.user
        }
    },
};
</script>