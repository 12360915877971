import axios from 'axios';


// var API_BASE_URL = 'http://localhost:1337'
var API_BASE_URL = 'https://dbcenter.playerki.com'

function gettoken() {
    let loc_medata = window.localStorage.getItem('me');
    if (!loc_medata) {
        return null;
    }
    let medata = JSON.parse(loc_medata)
    if (!medata) {
        return null;
    }
    return medata.jwt;
}

function request(url, method, data, args = null, need_auth = true) {
    const _url = API_BASE_URL + url
    const header = {}

    if (need_auth) {
        header['Authorization'] = 'Bearer ' + gettoken()
    }

    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: _url,
            params: args,
            data: data,
            headers: header,
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}


export default {
    auth_local(data) {
        return request('/auth/local', 'post', data, {}, false)
    },
    auth_local_register(data) {
        return request('/auth/local/register', 'post', data, {}, false)
    },
    auth_forgot_password(data) {
        return request('/auth/forgot-password', 'post', data, {}, false)
    },
    auth_reset_password(data) {
        return request('/auth/reset-password', 'post', data, {}, false)
    },
    auth_send_email_confirmation(data) {
        return request('/auth/send-email-confirmation', 'post', data)
    },
    note_find(args) {
        return request('/notes', 'get', {}, args)
    },
    note_count() {
        return request('/notes/count', 'get', {}, {})
    },
    note_create(data) {
        return request('/notes', 'post', data, {})
    },
    note_delete(pk) {
        console.log(pk)
        return request('/notes/' + pk, 'delete', {}, {})
    },
    note_findnone(pk) {
        return request('/notes/' + pk, 'get', {}, {})
    },
    note_update(data) {
        return request('/notes/' + data.id, 'put', data, {})
    },
    tag_find(args) {
        return request('/tags', 'get', {}, args)
    },
}