<template>
    <v-app>
        <router-view name="nav"></router-view>
        <AppBar></AppBar>
        <v-main>
            <SnackBar />
            <router-view />
        </v-main>
        <Footer></Footer>
    </v-app>
</template>
<script>
import AppBar from '@/components/AppBar'
import Footer from '@/components/Footer'
import SnackBar from '@/components/SnackBar';

export default {
    name: 'App',
    components: {
        AppBar,
        Footer,
        SnackBar,
    },
    data: () => ({
        //
    }),
    methods: {
        auth_done(res) {
            window.localStorage.setItem("me", JSON.stringify(res));
            this.$router.push({
                path: "/"
            });
        },
        register_done(res) {
            this.$Event.emit('auth_done', res)
            this.$router.push({
                path: "/"
            });
        },
        has_authed() {
            let medata = window.localStorage.getItem('me');
            return medata !== null
        }
    },
    mounted: function() {
        this.$Event.on('auth_done', e => this.auth_done(e))
    }
};
</script>
<style>
/** {
    border: 1px solid;
}*/
</style>